import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Testing for Race Conditions with Redux",
  "subtitle": "This post will explain how to write unit tests for race conditions in a redux reducer.",
  "date": "2017-11-29T05:38:35.002Z",
  "layout": "post",
  "draft": false,
  "tags": ["javascript", "front end", "redux", "programming"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`One type of error that causes bugs is race conditions. They are often hard to detect and reproduce since they only happen in special circumstances. If you're using redux to store your state, it's easy to write unit tests that account for these special circumstances.`}</p>
    <p>{`Let's look at an example.`}</p>
    <h2>{`The search results screen`}</h2>
    <p>{`Suppose we have a long list of items which is also filterable by a search text input. These search results are served by an api endpoint, at most 20 results at a time.`}</p>
    <p>{`We have three actions defined to model this, an action that's dispatched whenever the user updates the text in the input:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"UPDATED_SEARCH"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"redu"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`And one action that's emitted when the search request gets resolved:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"RECEIVE_SEARCH_RESULTS"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"items"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`We have a simple reducer that returns a loading state when it receives an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`UPDATED_SEARCH`}</code>{` action, and the loaded results state when it receives a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`RECEIVE_SEARCH_RESULTS`}</code>{` action.`}</p>
    <p>{`Let's write a unit test for this reducer and see how it turns out.`}</p>
    <h2>{`Writing a unit test for your reducer.`}</h2>
    <p>{`The functions that define how state changes in redux are called reducers due to their signature (the shape of a function's arguments and return value). If you take a look at how `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`array.reduce`}</code>{` is defined, it takes the previous sum, the current item, and returns the next sum.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` endValue `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduce`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`prevSum`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` currentItem`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` prevSum `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` currentItem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
endValue `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 10`}</span></code></pre>{`
      `}</div>
    <p>{`Here, the sum of the numbers is considered the 'sum' of the reduce.`}</p>
    <p>{`The signature of the lambda here is exactly like that of a redux reducer. It takes a previous state (previous sum), an action (current item), and applies that action to the previous state, returning the next state:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`searchReducer`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`prevState`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`action`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`type `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'UPDATED_SEARCH'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` loading`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`action`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`type `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'RECEIVE_SEARCH_RESULTS'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      loading`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` action`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`payload`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`items
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`We can use our reducers in the same way if we think of the current state of our app as the "sum" of the reducer. If we have an array of actions, we could call reduce using our reducer, where the result would be the final state of our app after those actions are applied.`}</p>
    <p>{`For example, Let's suppose our user came in and typed 'Red' (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`searchAction1`}</code>{`), waited for some results (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`resultsAction1`}</code>{`), and then refined their search to 'Redux' (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`searchAction2`}</code>{`), and waited for more results (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`resultsAction2`}</code>{`).`}</p>
    <p>{`If we had variables for each of those actions, we can compute what the state `}<em parentName="p">{`should`}</em>{` look like after those actions have been applied: `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` endState `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
  searchAction1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  resultsAction1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  searchAction2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  resultsAction2
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduce`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`searchReducer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// should contain the items from the second result`}</span>{`
endState`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`items`}</code></pre>{`
      `}</div>
    <h2>{`The race condition`}</h2>
    <p>{`You've probably already noticed out the potential issue with our implementation. If not, suppose what would happen if the first search request took a very long time to resolve; so long, that the user didn't wait for the results and typed in a more exact query which returned `}<em parentName="p">{`before`}</em>{` the first request did.`}</p>
    <p>{`Here's a graphic of the request timing:  `}</p>
    <p><img parentName="p" {...{
        "src": "./race_timing.svg",
        "alt": "race_timing"
      }}></img></p>
    <p>{`We can write another unit test for this case just by re-ordering our events:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` endState `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
  searchAction1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  searchAction2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  resultsAction2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  resultsAction1   `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`--`}</span>{` first results come `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` after second results
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduce`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`searchReducer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// should still contain the items from the resultsAction2`}</span>{`
endState`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`items`}</code></pre>{`
      `}</div>
    <p>{`The interface at this point should show the results from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`resultsAction2`}</code>{`, but if we inspect the state of our app, we notice it contains the results of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`resultAction1`}</code>{`!`}</p>
    <p>{`This is known as a race condition, and it would be hard to detect since it only occurs when the results of the first request are returned after a subsequent request.`}</p>
    <h2>{`The solution`}</h2>
    <p>{`One simple way would be to attach timestamps to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`UPDATE_FILTER`}</code>{` and the corresponding `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`RECEIVE_SEARCH_RESULTS`}</code>{` action, and only apply actions with timestamps `}<em parentName="p">{`after`}</em>{` the last applied action.`}</p>
    <p>{`It'd be a good idea to create one action creator that combines these actions together. In practice this would look like:  `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`updateSearch`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`searchTerm`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` timestamp `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Date`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`dispatch`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`dispatch`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	  type`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"UPDATED_SEARCH"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	  payload`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	    searchTerm`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` searchTerm`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	    timestamp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` timestamp
	  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
	
	`}<span parentName="code" {...{
            "className": "token function"
          }}>{`getResults`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'/api/search'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
	  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`then`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`results`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`dispatch`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
		  type`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"RECEIVE_SEARCH_RESULTS"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
		  payload`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
		    items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` results`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
		    timestamp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` timestamp
		  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
		`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
	  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`Then, our reducer could be updated to simply discard actions which come with a timestamp before the most recent one: `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`actionIsValid`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`state`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` action`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`payload`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`timestamp `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>=`}</span>{` state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`timestamp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`searchReducer`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`prevState`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`action`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`type `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'UPDATED_SEARCH'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`actionIsValid`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` action`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      loading`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      timestamp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` action`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`payload`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`timestamp
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`action`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`type `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'RECEIVE_SEARCH_RESULTS'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`actionIsValid`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` action`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      loading`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      timestamp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` action`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`payload`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`timestamp
      items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` action`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`payload`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`items
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`We re-run our tests, and they both pass!`}</p>
    <p>{`The next time you're writing a unit test for a reducer, remember to include `}<em parentName="p">{`all`}</em>{` the possible action sequences that could happen, instead of just the ones you expect to happen, and you'll prevent a lot of obscure timing-related bugs.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      